import React from "react"
import { graphql,Link } from "gatsby"
import Layout from "components/layout"
import * as styles from "styles/works.module.css"
import * as styles_detail from "styles/content-detail.module.css"
import Meta from "components/meta"

const remark = ({
  data: {
    allMarkdownRemark: {
       totalCount,
       edges:node,
     
      },
      
  },
}) => {

 const blogs = process.env.NODE_ENV === 'production'
/*  ? node.filter((value) => { 
  console.log(JSON.stringify({value}));
  return !value.node.frontmatter.published
  } ) */
  ? node.filter((value) => value.node.frontmatter.published )

  : node
  
  return (

  <Layout page={"top"}>
    <Meta
    title= "WORKS"
    />
    <strong>WORKS ( {totalCount} ) </strong>
    {blogs.map(
      ({
        node: {
          id,
          frontmatter: { title, date,tag },
          fields: { slug },
          excerpt,
        },
      }) => {
        return(
      
        <div key={id} className={styles.box}>
          <h2 className={styles.title}>
            <Link to={`.${slug}`}>{title}</Link>
          </h2>
          <div className={styles_detail.content_date}>
          <span className={styles_detail.content_date}>Posted on</span> {date}
        </div> 
        <div className={styles_detail.content_tag}>
        <span className={styles_detail.content_tag}>Tag : </span>
        <ul className={styles_detail.list}>
        
        {tag.map(tag =>(
           <li key={tag} className={styles_detail.item}>
           <Link to={`/works/tag/${tag}` } >{tag}</Link>
           </li>
        )
        )}
        </ul>
        </div>
          <p>{excerpt}</p>
        </div>
        )
    })
  }
    </Layout>
  )}
    
export default remark;

export const query = graphql`
query {
  allMarkdownRemark(
    sort: {fields: [frontmatter___date],order: DESC}
    )
    {
    totalCount
    edges {
      node {
        id
        html
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "YYYY/MM/DD(ddd)")
          published
          tag
        }
        fields {
          slug
        }
        excerpt(format:PLAIN,pruneLength: 200,truncate: true)
      }
    }
    }}
    `




